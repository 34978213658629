<template>
    <div class="contouring">
        <!-- Div For Main Data Posting Tool After clicking data Posting Tool -->
        <b-row style="margin-left: 15px; margin-right: 0px">
            <b-container class="main-content mt-3 p-0 mb-4">
                <!-- <div class="my-3">Text Instructions Here</div> -->
                <b-button
                    rounded
                    class="buttonSelect"
                    @click="addContourRequest()"
                    :disabled="validateInput || contourInProgress"
                >
                    Submit contour
                </b-button>
                <div>
                    <h6></h6>
                    <please-wait-spinner
                        v-if="contourInProgress"
                    ></please-wait-spinner>
                    <b-form-group class="md-4 mb-0" role="group">
                        <CustomFloatingLabel
                            class="postingName"
                            :config="{
                                label: 'Layer name',
                                name: 'wrapper',
                                line: false,
                                scale: false,
                            }"
                        >
                            <input
                                type="text"
                                class="inputbox mb-4"
                                v-model="layerName"
                                autocomplete="off"
                            />
                        </CustomFloatingLabel>
                    </b-form-group>
                    <div>
                        <b-form-group
                            class="m-r-36"
                            id="input-group-3"
                            label-for="input-3"
                        >
                            <CustomFloatingLabel
                                class="FloatingTool"
                                :config="{
                                    label: 'Select the field to contour',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                            >
                                <b-form-select
                                    v-model="fieldName"
                                    :options="fieldData"
                                    text-field="fieldData"
                                    value-field="fieldData"
                                    @change="getContourDataRange"
                                >
                                    <template #first>
                                        <b-form-select-option
                                            :value="null"
                                            disabled
                                            >Field value</b-form-select-option
                                        >
                                    </template>
                                </b-form-select>
                            </CustomFloatingLabel>
                        </b-form-group>
                    </div>
                    <div>
                        <b-form-group
                            class="m-r-36"
                            id="input-group-3"
                            label-for="input-3"
                        >
                            <h5>Aggregate method</h5>
                            <div class="aggr mb-3">
                                <div
                                    v-for="(item, index) in aggregateOption"
                                    :key="index"
                                >
                                    <img
                                        src="../../../assets/OverflowMenu.svg"
                                        font-scale="1.5"
                                        class="overflow-icon"
                                        v-if="overflowMenu"
                                    />
                                    <div class="aggregate-row">
                                        <CustomFloatingLabel
                                            class="FloatingTool"
                                            :config="{
                                                label: 'Aggregate field',
                                                name: 'wrapper',
                                                line: false,
                                                scale: false,
                                            }"
                                        >
                                            <b-form-select
                                                v-model="
                                                    item.aggregateOptionField
                                                "
                                                @change="addAggrType(index)"
                                                id="inline-form-custom-select-pref"
                                                :options="
                                                    fieldAggregateDataUnique
                                                "
                                                text-field="fieldAggregateDataUnique"
                                                value-field="fieldAggregateDataUnique"
                                            >
                                                <template #first>
                                                    <b-form-select-option
                                                        :value="null"
                                                        disabled
                                                        >Aggregate
                                                        field</b-form-select-option
                                                    >
                                                </template>
                                            </b-form-select>
                                        </CustomFloatingLabel>
                                        <span
                                            v-if="index != 0"
                                            @click="deleteAggr(index)"
                                            ><img
                                                src="../../../assets/delete_icon.svg"
                                                class="ml-2"
                                        /></span>
                                    </div>
                                    <div
                                        v-for="(subItem,
                                        subIndex) in item.aggregateType"
                                        :key="subIndex"
                                    >
                                        <img
                                            src="../../../assets/L-shaped.svg"
                                        />
                                        <CustomFloatingLabel
                                            class="FloatingTool floatingIndent"
                                            :config="{
                                                label: 'Aggregate type',
                                                name: 'wrapper',
                                                line: false,
                                                scale: false,
                                            }"
                                        >
                                            <b-form-select
                                                class="aggrType m-r-36"
                                                id="inline-form-custom-select-pref"
                                                v-model="
                                                    subItem.aggregateTypeName
                                                "
                                                :options="
                                                    aggregateTypeData[index]
                                                "
                                                text-field="AggregateOptions"
                                                value-field="AggregateOptions"
                                                @change="getContourDataRange"
                                            >
                                                <!-- <option v-for="j in aggregateType" :key="j">{{ j }}</option> -->
                                                <template #first>
                                                    <b-form-select-option
                                                        :value="null"
                                                        disabled
                                                        >Aggregate
                                                        type</b-form-select-option
                                                    >
                                                </template>
                                            </b-form-select>
                                        </CustomFloatingLabel>
                                        <div
                                            class="dateAggregate"
                                            v-if="
                                                subItem.aggregateTypeName ==
                                                    'Closest to a provided date'
                                            "
                                        >
                                            <img
                                                src="../../../assets/L-shaped.svg"
                                                class="imgIndent"
                                            />
                                            <CustomFloatingLabel
                                                class="FloatingTool floatingdate"
                                                :config="{
                                                    label: 'Provided date',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }"
                                            >
                                                <input
                                                    class="avlFilter mt-2 mb-4"
                                                    type="date"
                                                    v-if="
                                                        subItem.aggregateTypeName ==
                                                            'Closest to a provided date'
                                                    "
                                                    v-model="
                                                        subItem.aggregateDate
                                                    "
                                                />
                                            </CustomFloatingLabel>
                                            <a
                                                @click="
                                                    deleteAggr(index, subIndex)
                                                "
                                                class="deletebtn"
                                            ></a>
                                        </div>
                                    </div>
                                    <!--  -->
                                </div>
                            </div>
                        </b-form-group>
                        <p
                            class="normal_input headergroup mt-1 add-btn"
                            @click="addAggr()"
                        >
                            Add another aggregate option +
                        </p>
                        <h5 class="py-1">Kriging method</h5>
                        <CustomFloatingLabel
                            class="FloatingTool"
                            :config="{
                                label: 'Kriging Method',
                                name: 'wrapper',
                                line: false,
                                scale: false,
                            }"
                        >
                            <b-form-select
                                v-model="krigingMethod"
                                :options="krigingOptions"
                                text-field="Kriging Method"
                                value-field="Kriging Method"
                            >
                                <template #first>
                                    <b-form-select-option :value="null" disabled
                                        >Kriging Method</b-form-select-option
                                    >
                                </template>
                            </b-form-select>
                        </CustomFloatingLabel>
                        <h5 class="py-1">Line Range</h5>
                        The 'Max line' value must be greater than 'Min line'
                        value,<br />
                        and the 'Contour interval' must be between the 'Min
                        line' and 'Max line' values
                        <b-form-group class="md-4 mb-0" role="group">
                            <CustomFloatingLabel
                                class="postingName value-cfl"
                                :config="{
                                    label:
                                        'Min line (>= ' +
                                        this.minMaxValues.min +
                                        ')',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                            >
                                <input
                                    type="number"
                                    step="0.01"
                                    class="inputbox mb-4"
                                    :class="{
                                        'is-invalid': !minValid,
                                    }"
                                    v-model.number="minValue"
                                    autocomplete="off"
                                />
                                <invalid-icon :show="!minValid" />
                            </CustomFloatingLabel>
                        </b-form-group>
                        <b-form-group class="md-4 mb-0" role="group">
                            <CustomFloatingLabel
                                class="postingName value-cfl"
                                :config="{
                                    label:
                                        'Max line (<= ' +
                                        this.minMaxValues.max +
                                        ')',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                            >
                                <input
                                    type="number"
                                    step="0.01"
                                    class="inputbox mb-4"
                                    :class="{
                                        'is-invalid': !maxValid,
                                    }"
                                    v-model.number="maxValue"
                                    autocomplete="off"
                                />
                                <invalid-icon :show="!maxValid" />
                            </CustomFloatingLabel>
                        </b-form-group>
                        <b-form-group class="md-4 mb-0" role="group">
                            <CustomFloatingLabel
                                class="postingName value-cfl"
                                :config="{
                                    label:
                                        'Contour Interval (>0 - ' +
                                        (this.maxValue - this.minValue).toFixed(
                                            2
                                        ) +
                                        ')',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                            >
                                <input
                                    type="number"
                                    step="0.01"
                                    class="inputbox mb-4"
                                    :class="{
                                        'is-invalid': !intervalValid,
                                    }"
                                    v-model.number="contourInterval"
                                    autocomplete="off"
                                />
                                <invalid-icon :show="!intervalValid" />
                            </CustomFloatingLabel>
                        </b-form-group>
                        <!-- <h5>Label Styles</h5>
                        <b-form-group id="input-group-3" label-for="input-3">
                            <CustomFloatingLabel
                                class="FloatingTool"
                                :config="{
                                    label: 'Size',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                            >
                                <b-form-select
                                    v-model="fontSize"
                                    :options="fontSizeData"
                                    text-field="Size"
                                    value-field="Size"
                                >
                                    <template #first>
                                        <b-form-select-option
                                            :value="null"
                                            disabled
                                            >Size</b-form-select-option
                                        >
                                    </template>
                                </b-form-select>
                            </CustomFloatingLabel>
                        </b-form-group>
                        <b-form-group class="md-4 mb-0" role="group">
                            <CustomFloatingLabel
                                class="FloatingTool"
                                :config="{
                                    label: 'Style',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                            >
                                <b-form-select
                                    v-model="fontStyle"
                                    :options="fontStyleData"
                                    text-field="Style"
                                    value-field="Style"
                                >
                                    <template #first>
                                        <b-form-select-option
                                            :value="null"
                                            disabled
                                            >Style</b-form-select-option
                                        >
                                    </template>
                                </b-form-select>
                            </CustomFloatingLabel>
                        </b-form-group>
                        <b-form-group class="md-4 mb-0" role="group">
                            <CustomFloatingLabel
                                class="FloatingTool"
                                :config="{
                                    label: 'Type',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                            >
                                <b-form-select
                                    v-model="fontName"
                                    :options="fontNameData"
                                    text-field="Type"
                                    value-field="Type"
                                >
                                    <template #first>
                                        <b-form-select-option
                                            :value="null"
                                            disabled
                                            >Type</b-form-select-option
                                        >
                                    </template>
                                </b-form-select>
                            </CustomFloatingLabel>
                        </b-form-group> -->
                        <h5>Line style</h5>
                        <div class="pickColor wrap">
                            <div class="">
                                <CustomFloatingLabel
                                    class="FloatingTool"
                                    :config="{
                                        label: 'Line color',
                                        name: 'wrapper',
                                        line: false,
                                        scale: false,
                                    }"
                                >
                                    <color-picker v-model="featureColor" />
                                </CustomFloatingLabel>
                            </div>
                        </div>
                        <b-form-group class="md-4 mb-0" role="group">
                            <CustomFloatingLabel
                                class="FloatingTool"
                                :config="{
                                    label: 'Stroke width',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                            >
                                <b-form-select
                                    id="inline-form-custom-select-pref"
                                    :value="1"
                                    v-model="lineWidth"
                                    :options="[
                                        //{ text: 'Stroke width', value: null },
                                        '1',
                                        '2',
                                        '3',
                                        '4',
                                        '5',
                                        '6',
                                        '7',
                                        '8',
                                        '9',
                                        '10',
                                    ]"
                                >
                                </b-form-select>
                            </CustomFloatingLabel>
                        </b-form-group>
                        <b-form-group class="md-4 mb-0" role="group">
                            <CustomFloatingLabel
                                class="FloatingTool"
                                :config="{
                                    label: 'Line style',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                            >
                                <b-form-select
                                    id="inline-form-custom-select-pref"
                                    :value="null"
                                    v-model="lineStyle"
                                >
                                    <b-form-select-option :value="null"
                                        >Line style</b-form-select-option
                                    >
                                    <b-form-select-option value="Solid"
                                        >Solid</b-form-select-option
                                    >
                                    <b-form-select-option value="Dashed"
                                        >Dashed</b-form-select-option
                                    >
                                </b-form-select>
                            </CustomFloatingLabel>
                        </b-form-group>
                        <h5 class="trans-sm">Transparency</h5>
                        <div class="d-flex my-4">
                            <span class="font-weight-bold indigo-text mr-2 mt-1"
                                >0</span
                            >
                            <form class="grey-color">
                                <input
                                    class="border-0 inputrange"
                                    type="range"
                                    v-model="transparencyValue"
                                    min="0"
                                    step="1"
                                    max="100"
                                />
                            </form>
                            <span class="font-weight-bold indigo-text ml-2 mt-1"
                                >100</span
                            >
                            <p margin-top="50px">
                                <input
                                    type="text"
                                    margin-top="50px"
                                    class="input-value"
                                    display="inline-block"
                                    min="0"
                                    max="100"
                                    maxlength="3"
                                    size="2.5ch"
                                    padding-top="5px"
                                    v-model.lazy="transparencyValue"
                                    v-on:keyup.enter="validateTransparency()"
                                    v-click-outside="validateTransparency()"
                                />%
                            </p>
                        </div>
                    </div>
                </div>
            </b-container>
        </b-row>
    </div>
</template>
<script>
import Vue from 'vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import router from '../../../router';

import { latLngBounds, circleMarker } from 'leaflet';

import PleaseWaitSpinner from '../PleaseWaitSpinner.vue';

import { userService } from '../../../services/users.service';
import { projectService } from '../../../services/project.service';
import { ColorPickerPlugin } from '@syncfusion/ej2-vue-inputs';
import { reportingService } from '../../../services/reporting.service';

import { datapostingMixin } from './dataposting.mixin';

import CustomFloatingLabel from '../../CustomFloatingLabel';

import InvalidIcon from '../InvalidIcon.vue';
import ColorPicker from '../ColorPicker.vue';

//Vue.use(MapsPlugin);
Vue.use(ColorPickerPlugin);

export default {
    components: {
        CustomFloatingLabel,
        PleaseWaitSpinner,
        InvalidIcon,
        ColorPicker,
    },
    name: 'contouringtools',
    props: {
        showReportingMenu: Boolean,
        hidetableSelect: Boolean,
        currentSwitchState: Boolean,
        datasetId: Number,
    },
    mixins: [datapostingMixin],
    data() {
        return {
            minValue: 0,
            maxValue: 0,
            minMaxValues: { max: 0, min: 0 },
            contourInterval: 0,
            transparencyValue: 0,
            lineWidth: 1,
            lineStyle: 'Solid',
            featureColor: '#000000',
            krigingOptions: ['Inverse Distance Weighting'],
            krigingMethod: 'Inverse Distance Weighting',
            aggregateTypeData: [],
            aggregateOption: [
                {
                    aggregateType: [],
                    aggregateOptionField: '',
                },
            ],
            modalLayerName: '',
            value: 0,
            options: [],
            fontStyle: 'Normal',
            pickColor: false,
            layerName: '',
            fieldName: null,
            fontSize: 10,
            fontName: 'Arial',
            overflowMenu: false,
            hideheader: 0,
            mapview: false,
            styleData: [],
            styleColorData: [],
            fieldData: [],
            fieldAggregateData: [],
            fieldAggregateDataUnique: [],
            showPage: false,
        };
    },
    created() {
        this.getFontSize();
        this.getfontName();
        this.getFontStyle();
        this.selectField('Contourable');
        this.selectAggregateField('Contour');
        this.allAggregateOption();

        this.aggregateOptionFromTable = JSON.parse(
            localStorage.getItem('aggregateOption')
        );

        if (localStorage.getItem('postingStatus') == 'true') {
            if (this.aggregateOptionFromTable) {
                this.aggregateOption = this.aggregateOptionFromTable;
                this.displayMap();
            }
        }
    },
    computed: {
        ...mapState({
            contourInProgress: (state) => state.oneMap.contourInProgress,
            gridFilteroptions: (state) => state.gridFilters.gridFilter,
            secondFilterOptions: (state) => state.gridFilters.secondLevelFilter,
        }),
        ...mapGetters('projects', { gridDatasetId: 'getGridDatasetId' }),
        validateInput() {
            let disableApply = false;
            if (this.layerName == '' || this.layerName == null) {
                disableApply = true;
            }
            if (this.fieldName == null || this.fieldName == '') {
                disableApply = true;
            }
            if (
                this.maxValue == 0 ||
                this.maxValue == null ||
                this.maxValue <= this.minValue
            ) {
                disableApply = true;
            }
            if (
                this.contourInterval <= 0 ||
                this.contourInterval == null ||
                this.contourInterval > this.maxValue - this.minValue
            ) {
                disableApply = true;
            }
            for (let i in this.aggregateOption) {
                if (
                    this.aggregateOption[i].aggregateOptionField == '' ||
                    this.aggregateOption[i].aggregateOptionField == null
                ) {
                    disableApply = true;
                } else {
                    if (this.aggregateOption[i].aggregateType.length > 0) {
                        for (let m in this.aggregateOption[i].aggregateType) {
                            if (
                                this.aggregateOption[i].aggregateType[m]
                                    .aggregateTypeName ==
                                'Closest to a provided date'
                            ) {
                                if (
                                    this.aggregateOption[i].aggregateType[m]
                                        .aggregateDate == ''
                                ) {
                                    disableApply = true;
                                }
                            }
                        }
                    }
                }
            }
            if (!this.minValid || !this.maxValid || !this.intervalValid) {
                disableApply = true;
            }

            return disableApply;
        },
        minValid() {
            return (
                this.minValue >= this.minMaxValues.min &&
                this.minValue <= this.maxValue
            );
        },
        maxValid() {
            return (
                this.maxValue <= this.minMaxValues.max &&
                this.maxValue >= this.minValue
            );
        },
        intervalValid() {
            if (this.maxValue == 0 && this.minValue == 0) {
                return this.contourInterval >= 0;
            } else {
                return (
                    this.contourInterval <= this.maxValue - this.minValue &&
                    this.contourInterval > 0
                );
            }
        },
    },
    mounted: function() {
        this.pagePermit();
    },
    methods: {
        ...mapMutations('oneMap', ['setContourInProgress']),
        ...mapMutations('common', ['setToastMessage']),
        // clears the UI and sets everything to the default values
        resetInput() {
            this.layerName = '';
            this.fieldName = this.fieldData[0];
            (this.aggregateOption = [
                {
                    aggregateOptionField: this.fieldAggregateDataUnique[0],
                    aggregateType: [
                        {
                            aggregateDate: '',
                            aggregateTypeName: '',
                        },
                    ],
                },
            ]),
                (this.krigingMethod = this.krigingOptions[0]);
            this.maxValue = 0;
            this.minValue = 0;
            this.contourInterval = 0;
            this.fontSize = 10;
            this.fontStyle = 'Normal';
            this.fontName = 'Arial';
            this.featureColor = '#000000';
            this.lineWidth = 1;
            this.lineStyle = 'Solid';
            this.transparencyValue = 0;
        },
        addContourRequest() {
            var datasetId = this.gridDatasetId;
            let aggregateDataFinal = this.buildAggregateString();
            const projectIds = this.$store.state.projects.selectedProjects.map(
                (project) => project.ProjectID
            );

            var contourParams = {
                dataManager: JSON.parse(this.gridFilteroptions),
                contourOptions: {
                    DisplayName: this.layerName,
                    MinValue: this.minValue,
                    MaxValue: this.maxValue,
                    Interval: this.contourInterval,
                    ContourField: this.fieldName,
                    FontSize: 12,
                    FontName: 'Arial',
                    FontStyle: 'Normal',
                    LineColor: this.featureColor,
                    LineStyle: this.lineStyle,
                    LineWidth: this.lineWidth,
                    Transparency: this.transparencyValue,
                    AggregateOption: this.aggregateOption,
                    DatasetId: this.gridDatasetId,
                    KrigingMethod: this.krigingMethod,
                    dataManager: JSON.parse(this.gridFilteroptions),
                    MinMaxValues: this.minMaxValues,
                },
            };
            this.setToastMessage({
                queueService: 'Contour',
                queueState: 'start',
                queueMessage: 'Started.',
                visible: true,
            });
            reportingService
                .submitContourRequest(
                    datasetId,
                    aggregateDataFinal,
                    this.fieldName,
                    projectIds,
                    contourParams
                )
                .then((res) => {
                    if (res.status == 200) {
                        this.setToastMessage({
                            queueService: 'Contour',
                            queueState: 'progress',
                            queueMessage: 'In progress...',
                            visible: true,
                        });
                        setTimeout(() => this.resetInput(), 1000);
                        this.$store.commit('projects/addContourLayer', {
                            jobID: res.data,
                            name: this.layerName,
                            ContourOptions: contourParams.contourOptions,
                        });
                        this.$store.commit('oneMap/mutateCloseSidebars', true);
                        this.setContourInProgress(res.data);
                    }
                })
                .catch((err) => {
                    this.setToastMessage({
                        queueService: 'Contour',
                        queueState: 'error',
                        queueMessage: 'Error!',
                        visible: true,
                    });
                });
            // this.postDataPoints(
            //     aggregateDataFinal,
            //     this.layerName + ' - Points'
            // );
        },
        pagePermit() {
            this.showPage = true;
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            let vm = this;
            data.forEach((element) => {
                if (element.PageName.includes('Contouring')) {
                    vm.showPage = true;
                }
            });
            setTimeout(() => {
                if (!this.showPage) {
                    this.$refs['permission-modal'].showPermissionModal();
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
@import '../../../../node_modules/@syncfusion/ej2-base/styles/bootstrap4.css';
@import '../../../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap4.css';
@import '../../../../node_modules/@syncfusion/ej2-popups/styles/bootstrap4.css';
@import '../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap4.css';
@import '../../../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap4.css';
.main-content {
    overflow: hidden;
    h5 {
        font-size: 18px;
        white-space: nowrap;
        color: #161616;
    }
    h3 {
        font-size: 22px;
    }
}
.main-content.edit-layer {
    max-height: 485px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: 0px;
}
.inputbox :focus {
    border: none;
}
.deletebtn {
    width: 48px;
    height: 48px;
    margin-top: -63px;
    background: url('../../../assets/delete_icon.svg') no-repeat right !important;
}
.pencil-icon {
    position: relative;
    cursor: pointer;
    width: 20px;
}
.buttonSelect {
    position: absolute;
    right: 50px;
}
.overflow-icon {
    position: absolute;
    margin-left: -37px;
    margin-top: 2px;
    cursor: pointer;
    width: 43px;
}
.inputbox :focus {
    outline: none;
    border: 1px solid !important;
}
.aggrType {
    margin-left: 36px;
    margin-top: -23px;
    width: 400px;
}
.headergroup {
    background-color: #6e6e6e;
    color: #fff;
    border: none;
    margin-bottom: 15px;
    width: 499px;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
}
.headergroup.edit-header {
    width: 241px;
    margin-bottom: 15px;
}
.input-value {
    display: inline-flex;
    background: #f3f3f3 0% 0% no-repeat padding-box;
    margin-left: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 6px;
    margin-right: 0px;
    text-align: center;
    border-style: hidden;
}
/* Colorpicker style*/
.wrap {
    width: 300px;
    text-align: center;
}

/* Input element customization */
.e-colorpicker-wrapper .e-split-btn-wrapper #element.e-input {
    height: 48px !important;
    opacity: 1 !important;
    position: initial !important;
    margin-left: -35px;
    padding-left: 30px;
}

#element.e-input {
    height: 16px;
    margin: 0;
    opacity: 1;
    position: relative;
    width: 75px;
    top: 18px;
    left: 45px;
    border: 0 !important;
}

.e-colorpicker-wrapper
    .e-split-btn-wrapper
    .e-input:focus
    + .e-btn.e-dropdown-btn {
    padding-bottom: 3px;
    border-bottom-width: 2px;
    border-bottom-color: #e3165b;
}
.wrap {
    display: inline-block;
    width: 499px;
    height: 48px;
    // padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background: #fff;
    vertical-align: middle;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-bottom: 20px;
    text-align: left;
}
#element.e-input {
    height: 16px;
    margin: 0;
    opacity: 1;
    position: relative;
    width: 75px;
    top: 18px;
    left: 35px;
    border: 0 !important;
}

.contouring .portalbarMain {
    border-block: 0 !important;
}
.input-value {
    font-size: 14px;
}
.edit-layer .inputrange {
    width: 125px !important;
}
.edit-layer .headergroup {
    font-size: 14px;
}
.edit-layer .input-value {
    width: 55px;
    font-size: 12px;
}
.add-btn {
    padding-top: 10px !important;
    border-radius: 3px !important;
}
.contouring .edit-button {
    margin-top: 9px !important;
    color: #007bff !important;
    margin-right: 0px !important;
}
.imgIndent {
    position: relative;
    left: 35px;
    top: 27px;
}
.dateAggregate {
    margin-top: -22px;
}
.btn-color {
    position: relative;
    left: 460px;
    top: 10px;
    background-color: white;
    border: 0;
    height: 20px;
    width: 30px;
}
.colorDropper {
    position: absolute;
    left: 5px;
}

.dataposting .pickColor .e-colorpicker-wrapper .e-btn.e-btn.e-icon-btn {
    width: 45px !important;
}

.pickColor button {
    float: right;
    background: #fff;
    border: 0;
    margin-top: -17px;
}

.btn-transparent {
    position: inherit;
    top: 20px;
    right: 10px;
    height: 40px;
    width: 40px;
}
.is-invalid {
    border-color: #dc3545;
}
.value-cfl {
    max-height: 50px;
}
</style>
