var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contouring"},[_c('b-row',{staticStyle:{"margin-left":"15px","margin-right":"0px"}},[_c('b-container',{staticClass:"main-content mt-3 p-0 mb-4"},[_c('b-button',{staticClass:"buttonSelect",attrs:{"rounded":"","disabled":_vm.validateInput || _vm.contourInProgress},on:{"click":function($event){return _vm.addContourRequest()}}},[_vm._v(" Submit contour ")]),_c('div',[_c('h6'),(_vm.contourInProgress)?_c('please-wait-spinner'):_vm._e(),_c('b-form-group',{staticClass:"md-4 mb-0",attrs:{"role":"group"}},[_c('CustomFloatingLabel',{staticClass:"postingName",attrs:{"config":{
                            label: 'Layer name',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                        }}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.layerName),expression:"layerName"}],staticClass:"inputbox mb-4",attrs:{"type":"text","autocomplete":"off"},domProps:{"value":(_vm.layerName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.layerName=$event.target.value}}})])],1),_c('div',[_c('b-form-group',{staticClass:"m-r-36",attrs:{"id":"input-group-3","label-for":"input-3"}},[_c('CustomFloatingLabel',{staticClass:"FloatingTool",attrs:{"config":{
                                label: 'Select the field to contour',
                                name: 'wrapper',
                                line: false,
                                scale: false,
                            }}},[_c('b-form-select',{attrs:{"options":_vm.fieldData,"text-field":"fieldData","value-field":"fieldData"},on:{"change":_vm.getContourDataRange},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Field value")])]},proxy:true}]),model:{value:(_vm.fieldName),callback:function ($$v) {_vm.fieldName=$$v},expression:"fieldName"}})],1)],1)],1),_c('div',[_c('b-form-group',{staticClass:"m-r-36",attrs:{"id":"input-group-3","label-for":"input-3"}},[_c('h5',[_vm._v("Aggregate method")]),_c('div',{staticClass:"aggr mb-3"},_vm._l((_vm.aggregateOption),function(item,index){return _c('div',{key:index},[(_vm.overflowMenu)?_c('img',{staticClass:"overflow-icon",attrs:{"src":require("../../../assets/OverflowMenu.svg"),"font-scale":"1.5"}}):_vm._e(),_c('div',{staticClass:"aggregate-row"},[_c('CustomFloatingLabel',{staticClass:"FloatingTool",attrs:{"config":{
                                            label: 'Aggregate field',
                                            name: 'wrapper',
                                            line: false,
                                            scale: false,
                                        }}},[_c('b-form-select',{attrs:{"id":"inline-form-custom-select-pref","options":_vm.fieldAggregateDataUnique,"text-field":"fieldAggregateDataUnique","value-field":"fieldAggregateDataUnique"},on:{"change":function($event){return _vm.addAggrType(index)}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Aggregate field")])]},proxy:true}],null,true),model:{value:(
                                                item.aggregateOptionField
                                            ),callback:function ($$v) {_vm.$set(item, "aggregateOptionField", $$v)},expression:"\n                                                item.aggregateOptionField\n                                            "}})],1),(index != 0)?_c('span',{on:{"click":function($event){return _vm.deleteAggr(index)}}},[_c('img',{staticClass:"ml-2",attrs:{"src":require("../../../assets/delete_icon.svg")}})]):_vm._e()],1),_vm._l((item.aggregateType),function(subItem,subIndex){return _c('div',{key:subIndex},[_c('img',{attrs:{"src":require("../../../assets/L-shaped.svg")}}),_c('CustomFloatingLabel',{staticClass:"FloatingTool floatingIndent",attrs:{"config":{
                                            label: 'Aggregate type',
                                            name: 'wrapper',
                                            line: false,
                                            scale: false,
                                        }}},[_c('b-form-select',{staticClass:"aggrType m-r-36",attrs:{"id":"inline-form-custom-select-pref","options":_vm.aggregateTypeData[index],"text-field":"AggregateOptions","value-field":"AggregateOptions"},on:{"change":_vm.getContourDataRange},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Aggregate type")])]},proxy:true}],null,true),model:{value:(
                                                subItem.aggregateTypeName
                                            ),callback:function ($$v) {_vm.$set(subItem, "aggregateTypeName", $$v)},expression:"\n                                                subItem.aggregateTypeName\n                                            "}})],1),(
                                            subItem.aggregateTypeName ==
                                                'Closest to a provided date'
                                        )?_c('div',{staticClass:"dateAggregate"},[_c('img',{staticClass:"imgIndent",attrs:{"src":require("../../../assets/L-shaped.svg")}}),_c('CustomFloatingLabel',{staticClass:"FloatingTool floatingdate",attrs:{"config":{
                                                label: 'Provided date',
                                                name: 'wrapper',
                                                line: false,
                                                scale: false,
                                            }}},[(
                                                    subItem.aggregateTypeName ==
                                                        'Closest to a provided date'
                                                )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                    subItem.aggregateDate
                                                ),expression:"\n                                                    subItem.aggregateDate\n                                                "}],staticClass:"avlFilter mt-2 mb-4",attrs:{"type":"date"},domProps:{"value":(
                                                    subItem.aggregateDate
                                                )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(subItem, "aggregateDate", $event.target.value)}}}):_vm._e()]),_c('a',{staticClass:"deletebtn",on:{"click":function($event){return _vm.deleteAggr(index, subIndex)}}})],1):_vm._e()],1)})],2)}),0)]),_c('p',{staticClass:"normal_input headergroup mt-1 add-btn",on:{"click":function($event){return _vm.addAggr()}}},[_vm._v(" Add another aggregate option + ")]),_c('h5',{staticClass:"py-1"},[_vm._v("Kriging method")]),_c('CustomFloatingLabel',{staticClass:"FloatingTool",attrs:{"config":{
                            label: 'Kriging Method',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                        }}},[_c('b-form-select',{attrs:{"options":_vm.krigingOptions,"text-field":"Kriging Method","value-field":"Kriging Method"},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Kriging Method")])]},proxy:true}]),model:{value:(_vm.krigingMethod),callback:function ($$v) {_vm.krigingMethod=$$v},expression:"krigingMethod"}})],1),_c('h5',{staticClass:"py-1"},[_vm._v("Line Range")]),_vm._v(" The 'Max line' value must be greater than 'Min line' value,"),_c('br'),_vm._v(" and the 'Contour interval' must be between the 'Min line' and 'Max line' values "),_c('b-form-group',{staticClass:"md-4 mb-0",attrs:{"role":"group"}},[_c('CustomFloatingLabel',{staticClass:"postingName value-cfl",attrs:{"config":{
                                label:
                                    'Min line (>= ' +
                                    this.minMaxValues.min +
                                    ')',
                                name: 'wrapper',
                                line: false,
                                scale: false,
                            }}},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.minValue),expression:"minValue",modifiers:{"number":true}}],staticClass:"inputbox mb-4",class:{
                                    'is-invalid': !_vm.minValid,
                                },attrs:{"type":"number","step":"0.01","autocomplete":"off"},domProps:{"value":(_vm.minValue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.minValue=_vm._n($event.target.value)},"blur":function($event){return _vm.$forceUpdate()}}}),_c('invalid-icon',{attrs:{"show":!_vm.minValid}})],1)],1),_c('b-form-group',{staticClass:"md-4 mb-0",attrs:{"role":"group"}},[_c('CustomFloatingLabel',{staticClass:"postingName value-cfl",attrs:{"config":{
                                label:
                                    'Max line (<= ' +
                                    this.minMaxValues.max +
                                    ')',
                                name: 'wrapper',
                                line: false,
                                scale: false,
                            }}},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.maxValue),expression:"maxValue",modifiers:{"number":true}}],staticClass:"inputbox mb-4",class:{
                                    'is-invalid': !_vm.maxValid,
                                },attrs:{"type":"number","step":"0.01","autocomplete":"off"},domProps:{"value":(_vm.maxValue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.maxValue=_vm._n($event.target.value)},"blur":function($event){return _vm.$forceUpdate()}}}),_c('invalid-icon',{attrs:{"show":!_vm.maxValid}})],1)],1),_c('b-form-group',{staticClass:"md-4 mb-0",attrs:{"role":"group"}},[_c('CustomFloatingLabel',{staticClass:"postingName value-cfl",attrs:{"config":{
                                label:
                                    'Contour Interval (>0 - ' +
                                    (this.maxValue - this.minValue).toFixed(
                                        2
                                    ) +
                                    ')',
                                name: 'wrapper',
                                line: false,
                                scale: false,
                            }}},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.contourInterval),expression:"contourInterval",modifiers:{"number":true}}],staticClass:"inputbox mb-4",class:{
                                    'is-invalid': !_vm.intervalValid,
                                },attrs:{"type":"number","step":"0.01","autocomplete":"off"},domProps:{"value":(_vm.contourInterval)},on:{"input":function($event){if($event.target.composing){ return; }_vm.contourInterval=_vm._n($event.target.value)},"blur":function($event){return _vm.$forceUpdate()}}}),_c('invalid-icon',{attrs:{"show":!_vm.intervalValid}})],1)],1),_c('h5',[_vm._v("Line style")]),_c('div',{staticClass:"pickColor wrap"},[_c('div',{},[_c('CustomFloatingLabel',{staticClass:"FloatingTool",attrs:{"config":{
                                    label: 'Line color',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }}},[_c('color-picker',{model:{value:(_vm.featureColor),callback:function ($$v) {_vm.featureColor=$$v},expression:"featureColor"}})],1)],1)]),_c('b-form-group',{staticClass:"md-4 mb-0",attrs:{"role":"group"}},[_c('CustomFloatingLabel',{staticClass:"FloatingTool",attrs:{"config":{
                                label: 'Stroke width',
                                name: 'wrapper',
                                line: false,
                                scale: false,
                            }}},[_c('b-form-select',{attrs:{"id":"inline-form-custom-select-pref","value":1,"options":[
                                    //{ text: 'Stroke width', value: null },
                                    '1',
                                    '2',
                                    '3',
                                    '4',
                                    '5',
                                    '6',
                                    '7',
                                    '8',
                                    '9',
                                    '10' ]},model:{value:(_vm.lineWidth),callback:function ($$v) {_vm.lineWidth=$$v},expression:"lineWidth"}})],1)],1),_c('b-form-group',{staticClass:"md-4 mb-0",attrs:{"role":"group"}},[_c('CustomFloatingLabel',{staticClass:"FloatingTool",attrs:{"config":{
                                label: 'Line style',
                                name: 'wrapper',
                                line: false,
                                scale: false,
                            }}},[_c('b-form-select',{attrs:{"id":"inline-form-custom-select-pref","value":null},model:{value:(_vm.lineStyle),callback:function ($$v) {_vm.lineStyle=$$v},expression:"lineStyle"}},[_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("Line style")]),_c('b-form-select-option',{attrs:{"value":"Solid"}},[_vm._v("Solid")]),_c('b-form-select-option',{attrs:{"value":"Dashed"}},[_vm._v("Dashed")])],1)],1)],1),_c('h5',{staticClass:"trans-sm"},[_vm._v("Transparency")]),_c('div',{staticClass:"d-flex my-4"},[_c('span',{staticClass:"font-weight-bold indigo-text mr-2 mt-1"},[_vm._v("0")]),_c('form',{staticClass:"grey-color"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.transparencyValue),expression:"transparencyValue"}],staticClass:"border-0 inputrange",attrs:{"type":"range","min":"0","step":"1","max":"100"},domProps:{"value":(_vm.transparencyValue)},on:{"__r":function($event){_vm.transparencyValue=$event.target.value}}})]),_c('span',{staticClass:"font-weight-bold indigo-text ml-2 mt-1"},[_vm._v("100")]),_c('p',{attrs:{"margin-top":"50px"}},[_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.transparencyValue),expression:"transparencyValue",modifiers:{"lazy":true}},{name:"click-outside",rawName:"v-click-outside",value:(_vm.validateTransparency()),expression:"validateTransparency()"}],staticClass:"input-value",attrs:{"type":"text","margin-top":"50px","display":"inline-block","min":"0","max":"100","maxlength":"3","size":"2.5ch","padding-top":"5px"},domProps:{"value":(_vm.transparencyValue)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.validateTransparency()},"change":function($event){_vm.transparencyValue=$event.target.value}}}),_vm._v("% ")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }